import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import axios from 'axios'
import { Layout } from '../components/layout'
import { SEO } from '../components/seo'

const ContactPage = () => {
	const [lang, setLang] = useState('')
	const [complete, setComplete] = useState(false)

	const formik = useFormik({
		validateOnChange: false,
		initialValues: {
			name: '',
			email: '',
			content: ''
		},
		validate: values => {
			const errors = {}

			if (!values.name) {
				errors.name = 'お名前は必須です'
			}
			if (!values.email) {
				errors.email = 'メールアドレスは必須です'
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
				errors.email = 'メールアドレス が無効な形式です'
			}
			if (!values.content) {
				errors.content = 'お問い合わせ内容は必須です'
			}

			return errors
		},
		onSubmit: values => {
			axios.post('https://us-central1-icm-portal.cloudfunctions.net/sendMail', {
				data: values
			}).then(res => {
				console.log(res)
				setComplete(true)
			}).catch(e => {
				console.log(e)
			})
		},
	})

	useEffect(() => {
		localStorage.getItem('lang') ? setLang(localStorage.getItem('lang')) : setLang('ja')
	}, [])

	return (
		<Layout>
			<SEO
				title='お問い合わせはこちらから'
				description='プロジェクトやコラボレーションなど、INSPIRATION CULTへのご依頼・お問合わせはこちらから。ぜひアイディアをお聞かせください。'
				keywords={['お問い合わせ', '写真', '動画', 'コラボ', 'icm', 'INSPIRATION CULT']}
			/>
			<div className="contact">
				<div className="title">
					<div>
						BUSINESS<br />
						CONTACT
					</div>
				</div>
				<div className={`description ${lang === 'en' ? 'en' : ''}`}>
					{lang === 'ja' ? (
						<div>
							すべての壁をボーダレスに。<br />
							お声を頂くことからストーリーは動きはじます。<br />
							コラボレーション企画・広告掲載などに関しましては<br />
							コンタクトフォームよりお問い合わせください。
						</div>
					) : (
						<div>
							We challenge ourselves to be borderless with you.<br className="pc" /> The story will begin from your voice.<br />
							Feel free to contact us about collaboration projects<br className="pc" /> and any inquiries of advertisement.
						</div>
					)}

				</div>

				{complete ? (
					<div className="form-submitted">お問い合わせを受け付けました。</div>
				) : (
					<div className="form-section">
						<form onSubmit={formik.handleSubmit}>
							<div className="form-item">
								<label htmlFor="name">お名前</label>
								{formik.errors.name ? <div className="form-error">{formik.errors.name}</div> : ''}
								<input id="name" type="text" onChange={formik.handleChange} value={formik.values.name} />
							</div>

							<div className="form-item">
								<label htmlFor="email">メールアドレス</label>
								{formik.errors.email ? <div className="form-error">{formik.errors.email}</div> : ''}
								<input id="email" type="email" onChange={formik.handleChange} value={formik.values.email} />
							</div>

							<div className="form-item">
								<label htmlFor="content">お問い合わせ内容</label>
								{formik.errors.content ? <div className="form-error">{formik.errors.content}</div> : ''}
								<textarea id="content" onChange={formik.handleChange} value={formik.values.content} />
							</div>

							<div className="button-wrapper">
								<button type="submit">send</button>
							</div>
						</form>
					</div>
				)}
			</div>
		</Layout>
	)
}

export default ContactPage
